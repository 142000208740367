import React from 'react'
import {db} from '../firebase';
import { collection, getDocs, query, deleteDoc, doc as docSnapshot, where, orderBy, limit, addDoc, updateDoc  } from "firebase/firestore";
import exportFromJSON from 'export-from-json'
import * as xlsx from 'xlsx';

const Download = () => {

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            json.forEach(element => {
                element['registered'] = 'No'
                if (element['DEPARTMENT'] === undefined) {

                }
                if (element['TABLE'] === undefined) {

                }
                console.log(element['REMARKS'])
                addDoc(collection(db, "singaporepressclub"), element)
            });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }
}

    const docRef = query(collection(db, "singaporepressclub"))

    const downloadLatest = () => {
        getDocs(docRef)
            .then((snapshot) => {
                let information = []
                snapshot.docs.forEach((doc) => {
                  information.push(doc.data())
            })
        let data = JSON.parse(JSON.stringify(information))
        const fileName = 'vacawards'
        const exportType =  exportFromJSON.types.csv
    
        exportFromJSON({ data, fileName, exportType })
      })
      .catch(err => {
        console.log(err.message)
      })
    }

    
  return (
    <div><button onClick={downloadLatest}>Download</button>
      <input
            type="file"
            name="upload"
            id="upload"
            onChange={readUploadFile}
        /></div>
  )
}

export default Download