import React, { useEffect, useState, useRef } from 'react'
import { collection, getDocs, query, where, doc as docSnapshot, updateDoc, orderBy } from "firebase/firestore";
import {db} from '../firebase';
import exportFromJSON from 'export-from-json'
import Modal from 'react-modal';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import { SlMagnifier } from 'react-icons/sl'
import * as XLSX from 'xlsx'
import { FaCheck, FaCheckCircle, FaEllipsisH  } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const Scan = () => {

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  const [value, setValue] = useState("");
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [entity, setEntity] = useState("")
  const [diet, setDiet] = useState("")
  const [employeeID, setEmployeeID] = useState("")
  const [table, setTable] = useState("")
  const [unique, setUnique] = useState("")
  const [position, setPosition ] = useState("")
  const [country, setCountry ] = useState("")
  const [searchText, setSearchText] = useState("")
  const [registeredCount, setRegisteredCount] = useState(0)
  const [unregisteredCount, setUnregisteredCount] = useState(0)
  const [awardeesCount, setAwardeesCount] = useState(0)
  const [vipsCount, setVipsCount] = useState(0)
  const [disabled, setdisabled] = useState(false)
  const [dataKeyArray, setDataKeyArray] = useState([])
  const [dataArray, setDataArray] = useState([])

  const docRef = query(collection(db, "singaporepressclub"))
  const registeredRef = query(collection(db, "singaporepressclub"), where("registered", "==", "Yes"));
  const unRegisteredRef = query(collection(db, "singaporepressclub"), where("registered", "==", "No"));
  const vipRef = query(collection(db, "singaporepressclub"), where("REMARKS", "==", "#"), where("registered", "==", "Yes"));
  const awardeesRef = query(collection(db, "singaporepressclub"), where("AWARD", "!=", 'undefined'), where("registered", "==", "Yes"));

  function loopData(data) {
    let loopData = []
    for (let i=0; i< dataKeyArray.length; i++) {
      loopData.push(<td className="px-6 py-4">{data[dataKeyArray[i]]}</td>)
    }
    return (
      loopData
    )
  }

  function loopDataName(data) {
    let loopData = []
    loopData.push(<td className="inline-block px-6 py-4">{data['NAME']}</td>)
  
    return (
      loopData
    )
  }

  useEffect(() => {
    let information = []
    getDocs(docRef)
    .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          information.push(doc.data())
    })
      setSearch(information)
    })  
  },[])
            

  useEffect(() => {
    
getDocs(docRef).then((querySnapshot) => {
        let json
        querySnapshot.forEach((doc) => {
          let row = doc.data();
          if (String(row['REMARKS']).includes('#')) {
            updateDoc(docSnapshot(db, "singaporepressclub", doc.id), {
              vip: 'Yes'
            });
            json = {'UNIQUE NUMBER': row['UNIQUE NUMBER'], 'NAME': row['NAME'], 'DESIGNATION': row['DESIGNATION'],'COMPANY': row['COMPANY'], "PHONE NUMBER": row['PHONE NUMBER'],'TABLE': row['TABLE'], 'registered': row['registered'], 'registeredTimeStamp': row['registeredTimeStamp'], 'REMARKS': row['REMARKS'], vip: 'Yes'}
          } else {
            json = {'UNIQUE NUMBER': row['UNIQUE NUMBER'], 'NAME': row['NAME'], 'DESIGNATION': row['DESIGNATION'],'COMPANY': row['COMPANY'], "PHONE NUMBER": row["PHONE NUMBER"], 'TABLE': row['TABLE'], 'registered': row['registered'], 'registeredTimeStamp': row['registeredTimeStamp'], 'REMARKS': row['REMARKS']}
            console.log(json)
          }
       
        })
      setDataArray(json)
      try {
        setDataKeyArray(Object.keys(json))
      } catch {
        
      }
    })

    async function registeredCountF() {
      let count = 0
      await getDocs(registeredRef)
      .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            count += 1
            if (doc.data().EXTRA !== undefined )
            {
              count += 1
            } 
        })
        setRegisteredCount(count)
      })   
    };

    async function vipCountF() {
      let count = 0
      await getDocs(vipRef)
      .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            count += 1
            if (doc.data().EXTRA !== undefined)
            {
              count += 1
            } 
        })
        setVipsCount(count)
      })   
    };

    async function awardeesCountF() {
      await getDocs(awardeesRef)
      .then((snapshot) => {
        const count = snapshot.size
        setAwardeesCount(count)
      })   
    };
      
  async function unRegisteredCountF() {
    let count = 0
    await getDocs(unRegisteredRef)
    .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          count += 1
          if (doc.data().EXTRA !== undefined )
          {
            count += 1
          } 
      })
      setUnregisteredCount(count)
    })   
  };

    registeredCountF()
    unRegisteredCountF()
    vipCountF() 
    awardeesCountF()
  }, [registeredCount, unregisteredCount]);


  const handleChange = (event) => {
    setValue(event.target.value)
  }


  let timeoutId
  const [isHide, setIsHide] = useState(true);
  const [isHappening, setIsHappening] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);

  const handleKeyDown = (event) => {
   
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    
        const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    
        var currentdate = new Date(); 
    
        let h = currentdate.getHours();
        h = h < 10 ? "0" + h : h;
        let m = currentdate.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = currentdate.getSeconds();
        s = s < 10 ? "0" + s : s;
    
    
        var datetime =  Days[currentdate.getDay()] + ", "
                        + currentdate.getDate() + " "
                        + monthNames[currentdate.getMonth()] + " "
                        + currentdate.getFullYear() + ", "  
                        + h + ":"  
                        + m + ":" 
                        + s;
                        
    if (event.key === 'Enter') {
      const scanRef = query(collection(db, "singaporepressclub"),where("UNIQUE NUMBER", "==", parseInt(value)));
      getDocs(scanRef).then((querySnapshot) => {
        if (value === "unlock") {
          setdisabled(false)
        } else if (querySnapshot.size === 0) {
            toast.error('Person not in registration list, refer them to trobleshooting counter.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
        } else {

          querySnapshot.forEach((doc) => {
            updateDoc(docSnapshot(db, "singaporepressclub", doc.id), {
              registered: "Yes",
              registeredTimeStamp : datetime
            });
            setUnique(doc.data()['UNIQUE NUMBER'])
            if (doc.data()['EXTRA'] !== undefined) {
              setPosition(2)
            } else {
              setPosition(1)
            }
            
            setName(doc.data()['NAME'])
            setEntity(doc.data()['COMPANY'])
            setDiet(doc.data().AWARD)
            setEmployeeID(doc.data()['EMPLOYEE ID'])
            setCountry(doc.data()['COUNTRY'])
            setEmail(doc.data()['EMAIL'])
            setTable(doc.data()['TABLE'])
            localStorage.setItem("userData", JSON.stringify(doc.data()));
            setUnregisteredCount(unregisteredCount - 1)
            setRegisteredCount(registeredCount + 1)

            setIsHide(true)
            if (isHappening === true) {
              setIsHappening(false)
              clearTimeout(myInterval.current)
              setIsHappening(true)
              hideData()
            } else {
              hideData()
              setIsHappening(true)
            }

            if (doc.data().registered === 'Yes') {
              setIsAlreadyRegistered(true)
            } else {
              setIsAlreadyRegistered(false)
            }

                    
        })
        }

    })
      setValue('')
      setSearchText('')
    }
  }
  const myInterval = useRef(); 

  useEffect(() => {
    if (isHappening === true) {
      hideData()
    } else {
        return () => clearTimeout(myInterval.current)
    }
  }, [isHappening])

  function hideData() {
    myInterval.current = setTimeout(hide, 10000)
  }

  function hide() {
    setIsHide(false)
    setIsHappening(false)
    clearScreen()
  }


  const clearScreen = () => {
    localStorage.setItem("userData", 'false')
  }

  async function resetRegistration() {
    let count = 0
    getDocs(docRef).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        updateDoc(docSnapshot(db, "singaporepressclub", doc.id), {
          registered: "No",
          registeredTimeStamp: "",
        });
        count += 1
      })
    });
    setUnregisteredCount(count)
    setRegisteredCount(0)
    setdisabled(true)
  }

  const downloadLatest = () => {
    getDocs(docRef)
        .then((snapshot) => {
            let information = []
            snapshot.docs.forEach((doc) => {
              information.push(doc.data())
        })   
    const worksheet = XLSX.utils.json_to_sheet(information);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Latest Report.xlsx");
  })
  .catch(err => {
    console.log(err.message)
  })
}

const downloadLuckyDraw = () => {
  getDocs(registeredRef)
      .then((snapshot) => {
          let information = []
          snapshot.docs.forEach((doc) => {
            let row = doc.data()
            if (row['REMARKS'] === 'no' || row['REMARKS'] === 'No') {
              row['REMARKS'] = '#'
            }
            row = {'UNIQUE NUMBER': row['UNIQUE NUMBER'], 'NAME': row['NAME'], 'TABLE': row['TABLE'], 'COMPANY':row['COMPANY'], 'registered': row['registered'], 'registeredTimeStamp': row['registeredTimeStamp'], 'REMARKS': row['REMARKS'], ...row}
            information.push(row)
          })
    const worksheet = XLSX.utils.json_to_sheet(information);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Lucky Draw Upload.xlsx");
})
.catch(err => {
  console.log(err.message)
})
}

const downloadRespective = () => {
  let data = JSON.parse(JSON.stringify(search))
  const fileName = 'singaporepressclub'
  const exportType =  exportFromJSON.types.csv

  exportFromJSON({ data, fileName, exportType })
}

  const [modalIsOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState([])
  const [subtitle, setSubtitle] = useState()

  function openSearch() {
    setSubtitle('Search')
    setIsOpen(true);
    let information = []
    getDocs(docRef)
    .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          information.push(doc.data())
    })
    setSearch(information)
    })
  }


  function openRegistered() {
    setSubtitle('Registered')
    let information = []
    setIsOpen(true);
    getDocs(registeredRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              information.push(doc.data())
        })
      setSearch(information)
    })
  }

  function openFull() {
    setSubtitle('Full List')
    let information = []
    setIsOpen(true);
    getDocs(docRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              information.push(doc.data())
        })
      setSearch(information)
    })
  }


  
  function openUnregistered() {
    setSubtitle('Unregistered')
    let information = []
    setIsOpen(true);
    getDocs(unRegisteredRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              information.push(doc.data())
        })
      setSearch(information)
    })
  }

  function openVIP() {
    setSubtitle('VIP')
    let information = []
    setIsOpen(true);
    getDocs(vipRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              information.push(doc.data())
        })
      setSearch(information)
    })
  }

  function openAwardees() {
    setSubtitle('Awardees')
    let information = []
    setIsOpen(true);
    getDocs(awardeesRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              information.push(doc.data())
        })
      setSearch(information)
    })
  }


  async function closeModal() {
    setIsOpen(false);
    setSearchText('')
    setOpen(false);
    let information = []
    await getDocs(docRef)
    .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          information.push(doc.data())
    })
      setSearch(information)
    })  
  }

  const [radioChecked, setRadioChecked] = useState(false)

  useEffect(() => {
    console.log(radioChecked);
  }, [radioChecked]);

  function ShowDownloadButton(props) {
    const showDownload = props.subtitle;
    if (showDownload !== "Search") {
      return <button disabled={disabled} onClick={downloadRespective} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
                  Download {subtitle} Report
            </button>;
    }
    return
  }

  
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.target.matches('input')) {
        return
      }
      if (e.key === 'u') {
            setdisabled(false)
      } else if (e.key === 'l') {
        setdisabled(true)
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);


  const register = (data) => {
    if (data === undefined) {
      toast.warn('You have not selected a person, please select one first before registering.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    } else {
      var currentdate = new Date(); 
      var datetime =  currentdate.getDate() + "/"
                      + (currentdate.getMonth()+1)  + "/" 
                      + currentdate.getFullYear() + " @ "  
                      + currentdate.getHours() + ":"  
                      + currentdate.getMinutes() + ":" 
                      + currentdate.getSeconds();
      const scanRef = query(collection(db, "singaporepressclub"), where("UNIQUE NUMBER", "==", parseInt(data['UNIQUE NUMBER'])));
      getDocs(scanRef).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            updateDoc(docSnapshot(db, "singaporepressclub", doc.id), {
              registered: "Yes",
              registeredTimeStamp: datetime
            })
            localStorage.setItem("userData", JSON.stringify(doc.data()));
            if (doc.data().EXTRA !== undefined )
            {
              setUnregisteredCount(unregisteredCount - 1)
              setRegisteredCount(registeredCount + 1)
            } else {
              setUnregisteredCount(unregisteredCount - 2)
              setRegisteredCount(registeredCount + 2)
            }
            
    
            if (doc.data().registered === 'Yes') {
              setIsAlreadyRegistered(true)
            } else {
              setIsAlreadyRegistered(false)
            }
            
            
            setRadioChecked(false)
            if (doc.data()['EXTRA'] !== undefined) {
              setPosition(2)
            } else {
              setPosition(1)
            }
            setUnique(doc.data()['UNIQUE NUMBER'])
            setName(doc.data().NAME)
            setEntity(doc.data().COMPANY)
            setDiet(doc.data().AWARD)
            setCountry(doc.data().COUNTRY)
            setTable(doc.data().TABLE)
            
          
        })
      })
      toast.success('Person Successfully Registered!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    setValue('')
    setSearchText('')

    setIsHide(true)
    if (isHappening === true) {
      setIsHappening(false)
      clearTimeout(myInterval.current)
      setIsHappening(true)
      hideData()
    } else {
      hideData()
      setIsHappening(true)
    }
  }

  const unregister = (data) => {
    if (data === undefined) {
      toast.warn('You have not selected a person, please select one first before registering.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    } else {
      const scanRef = query(collection(db, "singaporepressclub"),where("UNIQUE NUMBER", "==", parseInt(data['UNIQUE NUMBER'])));
      getDocs(scanRef).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            updateDoc(docSnapshot(db, "singaporepressclub", doc.id), {
              registered: "No",
              registeredTimeStamp: ""
            });
            if (doc.data().EXTRA !== undefined )
            {
              setUnregisteredCount(unregisteredCount + 2)
              setRegisteredCount(registeredCount - 2)
            } else {
              setUnregisteredCount(unregisteredCount + 1)
              setRegisteredCount(registeredCount - 1)

            }
        })
      })
      toast.success('Person Successfully Unregistered!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    }
    setValue('')
    setSearchText('')
  }

  const [toggle, setToggle] = useState(false)
  const [timerValue, setTimerValue] = useState("5")

  const handleTimerValue = (event) => {
    setTimerValue(event.target.value)
    localStorage.setItem("timerValue", event.target.value);
  }

  const handleToggle = () => {
    setToggle(!toggle)
    localStorage.setItem("toggle", !toggle);
  }

  function ShowResetButton() {
    const isDisabled = disabled
    if (isDisabled === true) {
      return <button type="button" className="text-white bg-red-400 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2" disabled>Reset</button>
    } else {

    }
    return <button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2" onClick={resetRegistration}>Reset</button>
  }



  const [sortType, setSortType] = useState("ascending");
  const [sortByField, setSortByField] = useState("title");
  function sortFunc(sortType, sortByField) {
    if (sortType === "ascending") {
      search.sort((a, b) => String(a[sortByField]) < String(b[sortByField]) ? -1 : 1)  
    }
    else if (sortType === "descending") {
      search.sort((a, b) => String(b[sortByField]) > String(a[sortByField]) ? 1 : -1)
    }
    return search;
  }

  function updatePosts(e) {
    setSortType(e);
    sortFunc(e, sortByField)
  }

  function sortBy(e) {
    setSortByField(e);
    sortFunc(sortType, e)
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className='bg-gray-700 h-svh pb-96'>
      <ToastContainer/>
    <div className='container m-auto'>
    <Modal
                      ariaHideApp={false}
                      isOpen={modalIsOpen}
                      style={customStyles}
                      onRequestClose={closeModal}
                      contentLabel="Example Modal"
                    >     
                      <p className='pb-4 text-xl font-bold text-center'>{subtitle}</p>
                      <div className='text-right'>
                      <span className='float-left mt-2 font-medium mr-2'>Sort By: </span>
                    <select className='float-left' defaultValue={'title'} onChange={(e) => sortBy(e.target.value)}>
                      <option value="DEFAULT">None</option>
                      <option value="NAME">Name</option>
                      <option value="TABLE">Table</option>
                      <option value="registeredTimeStamp">Time Registered</option>
                    </select>
                  
                    <select className='ml-2 float-left' defaultValue={'DEFAULT'} onChange={(e) => updatePosts(e.target.value)}>
                      <option value="DEFAULT">None</option>
                      <option value="ascending">Ascending</option>
                      <option value="descending">Descending</option>
                    </select>
                      <ShowDownloadButton subtitle={subtitle}/>
                      <button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2" onClick={closeModal}>Close</button>
                      </div> 
                      <input type="text" onChange={event => setSearchText(event.target.value)} className="my-10 bg-gray-50 border border-blue-300 text-blue-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Search Name Here..."></input>
                      <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr className='border-solid border-2 border-grey-200'>
                          <th></th>
                          <th></th>
                          {
                            dataKeyArray.map((data, index) => (
                              <th scope="col" className="px-6 py-3"key={index}>{data}</th>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                      {
                        search.filter(data => {
                          if (searchText === '') {
                            return data;
                          } 
                            else if (String(data['NAME']).toLowerCase().includes(searchText.toLowerCase()) || String(data['COMPANY']).toLowerCase().includes(searchText.toLowerCase())){
                              return data;
                            }
                          
                        }).map((data, index) => (
                          <tr className="bg-white border-b" key={index}>
                          <td className="py-4">
                          <button  onClick={() => {register(data)}} className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">
                            <FaCheck size={18}/>
                          </button>
                          <button  onClick={() => {unregister(data)}} className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">
                            <ImCross size={18}/>
                          </button>
                          </td>
                          <td></td>
                          {loopData(data)}
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                    </Modal>
                    <div className="grid grid-cols-3 gap-4 pt-2">
          <div className='col-span-2'>
            <div className='flex justify-center border-blue-400'>
            <p className='flex-none text-white font-bold text-xl'>Latest Data Displayed:</p>
            </div>

          
            <dl className="mt-2 pb-8 m-auto p-4 text-gray-900 divide-y divide-gray-200">
            <p className="flex py-3">
                    <dt className="mb-1 font-bold text-white">LD NO:</dt>
                    <dd className=" text-white ml-4">{unique}</dd>
                </p>
                <p className="flex py-3">
                    <dt className="mb-1 font-bold text-white">NAME:</dt>
                    <dd className=" text-white ml-4 w-full">{name}</dd>
                </p>
                <p className="flex py-2">
                    <dt className="text-lg mb-1 font-bold font-bold text-white">COMPANY:</dt>
                    <dd className="text-lg text-white ml-4 w-full w-full text-left">{entity}</dd>
                </p>
                <p className="flex py-2">
                    <dt className="w-28 text-lg mb-1 font-bold font-bold text-white">TABLE NO:</dt>
                    <dd className="text-lg font-bold text-white ml-4 w-full w-full text-left">{table}</dd>
                </p>
            </dl>
          </div>
          <div className="mt-6 w-96 h-60 mx-auto bg-white border border-gray-200 rounded-lg shadow">
          {isHide ? <FaCheckCircle color="green" className='mx-auto mt-10' size={120}/> : <FaEllipsisH className='mx-auto mt-10' size={120}/>}
          {isHide ? isAlreadyRegistered ? <p className='mx-auto text-red-500 text-center text-2xl font-bold'>Already Registered</p> :   <p className='mx-auto text-center text-2xl font-bold'>Registered</p> :  <p className='mx-auto text-center text-2xl font-bold'>Awaiting</p>}
      </div>
        </div>
        <div>
          <input autoFocus={true} className='w-full p-5 rounded-full text-center' value={searchText} placeholder="Click Here to Scan..." type="text" onChange={event => {setSearchText(event.target.value); handleChange(event);}} onKeyDown={handleKeyDown}/>
        </div>
        
        <div className='relative w-7/12 mx-auto'>
          <div className={`options ${open ? "hidden" : "block"}`}>
        {
            search.filter(data => {
              if (searchText === '') {
                return;
              } 
                else if (String(data['NAME']).toLowerCase().includes(searchText.toLowerCase()) || String(data['COMPANY']).toLowerCase().includes(searchText.toLowerCase())){
                  return data;
                }
              
            }).map((data, index) => (
                  <div className='option border bg-white' key={index}>
                  <button  onClick={() => {register(data)}} className="inline-block text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">
                    <FaCheck size={18}/>
                  </button>
                  <button  onClick={() => {unregister(data)}} className="inline-block text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">
                    <ImCross size={18}/>
                  </button>
                  {loopDataName(data)}
                  </div>
            ))
          }   
          </div>     
        </div>
        <div className='py-4 text-center'>
        <ShowResetButton/>
          <button disabled={disabled} onClick={clearScreen} className="inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 ">
            <span className="px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                Clear Screen
            </span>
          </button>
          {disabled? <Link className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
            Screen Page
          </Link> : 
          <Link to="/screen" target='_blank' type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
            Screen Page
          </Link>} 

          {disabled ? <Link className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
            Edit Page
          </Link> :
          <Link  to="/edit" target='_blank' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
            Edit Page
          </Link>} 
          
          <button disabled={disabled} onClick={downloadLuckyDraw} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2">
            Download Lucky Draw File
          </button>
          <label for="file-upload" className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2'>
          <input
          disabled={disabled}
          className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2'
                    id="file-upload"
                    type="file"
                    name="myImage"
                    placeholder='test'
                    onChange={(event) => {
                        const image = event.target.files[0];

                        const reader = new FileReader();

                        reader.readAsDataURL(image);

                        reader.addEventListener('load', () => {
                            localStorage.setItem('bgImage', reader.result);
                        });
                    }}
                />
                Upload Background
                </label>
        </div>
        <div className="grid grid-cols-5 gap-4 text-center">

<div>
  {/*<div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Awardees</h5>
        <p className='mb-2 text-3xl font-extrabold'>{awardeesCount}</p>
        <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300" >
          See List
          <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
          </div>*/}
    <div className="max-w-sm p-6 pb-10 pt-10 bg-white border border-gray-200 rounded-lg shadow">
      <h6 className="mb-2 text-xl font-bold tracking-tight text-gray-900">Total Registered</h6>
        <p className='mb-2 text-green-500 text-4xl font-extrabold'>{((registeredCount/(registeredCount + unregisteredCount))*100).toFixed(0) + '%'}</p>
    </div>
</div>
<div>
<div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Full List</h5>
        <p className='text-black mb-2 text-3xl font-extrabold'>{(registeredCount + unregisteredCount).toFixed(0)}</p>
        <button disabled={disabled} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300" onClick={() => {setOpen(true); openFull();}}>
          See List
          <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
</div>
<div>
<div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Registered</h5>
        <p className='text-green-500 mb-2 text-3xl font-extrabold'>{registeredCount}</p>
        <button disabled={disabled} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300" onClick={() => {setOpen(true); openRegistered();}}>
          See List
          <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
</div>
<div>
<div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Unregistered</h5>
        <p className='text-red-600 mb-2 text-3xl font-extrabold'>{unregisteredCount}</p>
        <button disabled={disabled} onClick={() => {setOpen(true); openUnregistered()}} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
          See List
          <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
  </div>
<div>
  <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">VIP</h5>
        <p className='mb-2 text-3xl font-extrabold'>{vipsCount}</p>
        <button disabled={disabled} onClick={() => {setOpen(true); openVIP();}} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
          See List
          <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
  </div>
</div>
      </div>
    </div>
  )
}

export default Scan
