import React, { useEffect, useState } from 'react'
import RSLogo from "./image/SPC logo.png"
import { Button, Typography } from '@material-tailwind/react';
import PressBallLogo from "./image/image-removebg-preview (2).png"
import { Fade } from 'react-awesome-reveal';

const Details = () => {
    let details



    useEffect(() => {
        details = localStorage.getItem('details')
        console.log(details)
      }, [])

    let department = localStorage.getItem("department")
    let table = localStorage.getItem("table")
    if (localStorage.getItem("department") !== undefined && localStorage.getItem("department") !== "undefined" && localStorage.getItem("department") !== "") {
        department = "Company: " + localStorage.getItem("department") 
    } else {
        department = ""
    }
    if (localStorage.getItem("table") !== undefined && localStorage.getItem("table") !== "undefined" && localStorage.getItem("table") !== "") {
        table = "Table No.: " + localStorage.getItem("table")
    } else {
        table = ""
    }
    const name = localStorage.getItem("name")

    const timeStamp = localStorage.getItem("timeStamp")

  return (
    <div className='pb-8'>
     <div class="container pt-8 px-6 rounded mx-auto ">
                    <img className='w-5/12 lg:w-3/12 mx-auto' src={RSLogo}/>
                </div>
                <div class="container py-8 lg:py-12 px-6 rounded text-center mx-auto ">
                    <img className='mx-auto w-9/12 lg:w-7/12' src={PressBallLogo}/>
                </div>
                <Fade pulse duration={5000}>
    <div className='text-sm lg:text-base pb-2 pt-12 px-6 mx-6 bg-white rounded-xl'>
        <p className='text-center pb-12 text-xl lg:text-2xl font-bold'>
            Registration Successful!
        </p>
        <p>
            Dear {name},
        </p>
        <br/>
        <p>
            Welcome to <b>Singapore Press Club Press Ball 2024!</b>
        </p>
        <br/>
        <p>
            You have successfully registered for the Lucky Draw.
        </p>
        <br/>
        <p className='underline'>
            Registrant's Details:
        </p>
        <p>
            {department}
        </p>
        <p className='pb-4'>
            {table}
        </p>
        <hr className='mt-5'/>
        <p className='py-6 text-sm lg:text-base'>Registered on {timeStamp}</p>
</div>      
</Fade>
</div>
  )
}

export default Details