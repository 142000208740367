import React, { useRef, useState, useEffect} from 'react'
import { collection, getDocs, query, where, doc as docSnapshot, updateDoc } from "firebase/firestore";
import {db} from '../firebase';
import { Link, useNavigate} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import RSLogo from "./image/SPC logo.png"
import PressBallLogo from "./image/image-removebg-preview (2).png"
import { Button, Typography } from '@material-tailwind/react';
import { GrFormNextLink } from "react-icons/gr";
import 'react-toastify/dist/ReactToastify.css';


export const Test = () => {

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

    const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    var currentdate = new Date(); 

    let h = currentdate.getHours();
    h = h < 10 ? "0" + h : h;
    let m = currentdate.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = currentdate.getSeconds();
    s = s < 10 ? "0" + s : s;


    var datetime =  Days[currentdate.getDay()] + ", "
                    + currentdate.getDate() + " "
                    + monthNames[currentdate.getMonth()] + " "
                    + currentdate.getFullYear() + ", "  
                    + h + ":"  
                    + m + ":" 
                    + s;
    console.log(datetime)

    const colRef = collection(db, 'singaporepressclub')

    const navigate = useNavigate()
 
    async function checkPhone(e) {
        e.preventDefault() 
        const phoneRef = query(colRef, where("PHONE NUMBER", "==", Number(phoneNumber)));
        let data 
        await getDocs(phoneRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
                data = doc
                localStorage.setItem("name", doc.data().NAME)
                localStorage.setItem("department", doc.data().COMPANY)
                localStorage.setItem("table", doc.data().TABLE)
                localStorage.setItem("table", doc.data().TABLE)
                if (doc.data().registered === "Yes") {
                    localStorage.setItem("timeStamp", doc.data().registeredTimeStamp)
                } else {
                    updateDoc(docSnapshot(db, "singaporepressclub", doc.id), {
                        registered: "Yes",
                        registeredTimeStamp: datetime,
                      });
                    localStorage.setItem("timeStamp", datetime)
                }

                navigate('/details')

        })
            if (data === undefined) {
                toast.error('This PHONE NUMBER is not registered, please try again!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        })   
  };

  const [phoneNumber, setPhoneNumber] = useState("")

    return (
        <div className='pb-8'>
            <ToastContainer/>
            <div class="container pt-8 px-6 rounded mx-auto ">
                    <img className='w-5/12 lg:w-3/12 mx-auto' src={RSLogo}/>
                </div>
                <div class="container py-8 lg:py-12 px-6 rounded text-center mx-auto ">
                    <img className='mx-auto w-9/12 lg:w-7/12' src={PressBallLogo}/>
                </div>
            <div className='pb-8 pt-12  mx-6 bg-white rounded-xl'>
                <p className='text-center pb-12 text-xl font-bold'>
                    Get your registration details!
                </p>
            <form onSubmit={checkPhone} className='grid grid-cols-12'>
                <div className='col-span-2 md:col-span-3'>
                </div>

                <div className='col-span-8 md:col-span-6'>

                    <div className='py-1'>
                        <label className="pt-2">Phone Number<span className='text-red-600'>*</span></label>
                        <br/>
                        <input name="phoneNumber" pattern="[0-9]{8}" minLength='8' maxLength='8' type="number" onInput={(e) => {if (e.target.value.length > 8) {e.target.value = e.target.value.substr(0,8)} setPhoneNumber(e.target.value)}} value={phoneNumber} className="w-full bg-white border-blue-600 border-1" placeholder='e.g. 91234567' required/>
                    </div>
                  
                </div>
                <div className='col-span-2 md:col-span-3'>
                </div>
                <div className='col-span-2 md:col-span-3'>
                </div>
                <div className='col-span-8 md:col-span-6 text-xs font-normal italic py-4'>
                
                </div>
                <div className='col-span-2 md:col-span-3'>
                </div>
                <div className='col-span-2 md:col-span-3'>
                </div>

                <div className='col-span-8 md:col-span-6 m-auto'>
                <Button onClick={checkPhone} size="lg"className="text-right flex items-center mt-3 py-3">
                    Submit <GrFormNextLink size={18}/>
                    
                </Button>
                </div>

                <div className='col-span-2 md:col-span-3'>
                </div>
                    </form>
                    
        </div>      
        </div>
        
);
};

export default Test
