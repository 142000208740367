import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom'
import Form from './components/Form';
import Download from './components/Download';
import Details from './components/Details';
import Scan from './components/Scan';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path='/' element={< Form />}></Route>  
      <Route exact path='/details' element={< Details />}></Route>  
      <Route exact path='/download' element={< Download />}></Route>  
      <Route exact path='/scan' element={<Scan/>}></Route>  
    </Routes>
  </Router>
);